import "construct-style-sheets-polyfill";
import style from "./style.css";
import axios from "axios";
import {forEach, get, concat, isArray, chain, isEmpty, compact} from "lodash";
import {
	arrayMove,
	getBreakpoint,
	ICountry,
	ISport,
	ISports,
	LanguageCode,
	LogoStripTier,
	REGIONS,
	Theme,
	TIER_COLUMN_MAP,
	TIER_TITLE_MAP,
	TIER_TITLES,
	TIERS,
} from "modules";
import FWWCFont from "assets/fonts/FWWC20203_WEB-SemiBold.woff";

class LogoStrip extends HTMLElement {
	static defaultTheme = Theme.Light;
	static defaultLanguage = LanguageCode.English;
	dataWithLogos?: ISports;
	country?: string;

	static get observedAttributes() {
		return [
			"data-competition",
			"data-event-name",
			"data-theme",
			"data-language",
			"data-tiers",
			"data-logo-type",
			"data-debug",
			"data-disable-geolocation",
		];
	}

	constructor() {
		super();

		this.loadDefaultFont();
		this.loadCustomFonts();

		const shadow = this.attachShadow({mode: "open"});
		const styleTag = document.createElement("style");
		styleTag.innerHTML = style;
		shadow.appendChild(styleTag);

		const div = document.createElement("div");
		div.classList.add("wrapper");
		shadow.appendChild(div);

		this.render = this.render.bind(this);
	}

	get competition() {
		return this.getAttribute("data-competition");
	}

	get eventName() {
		return this.getAttribute("data-event-name");
	}

	get logoType() {
		return this.getAttribute("data-logo-type");
	}

	get theme() {
		const attribute = this.getAttribute("data-theme");
		if (attribute && Object.values(Theme).includes(attribute as Theme)) {
			return attribute as Theme;
		}
		return LogoStrip.defaultTheme;
	}

	get language() {
		const attribute = this.getAttribute("data-language");
		if (attribute && Object.values(LanguageCode).includes(attribute as LanguageCode)) {
			return attribute as LanguageCode;
		}
		return LogoStrip.defaultLanguage;
	}

	get tiers() {
		const tierAttribute = this.getAttribute("data-tiers");
		const tierList: LogoStripTier[] = tierAttribute ? JSON.parse(tierAttribute) : [];
		if (isArray(tierList) && !isEmpty(tierList)) {
			return TIERS.filter((tier) => tierList.includes(tier));
		}
		return TIERS;
	}

	get debug() {
		return this.getAttribute("data-debug") === "true" ? true : false;
	}

	get debugData() {
		return [
			{label: "Country", value: this.country},
			{label: "Region", value: this.region},
			{label: "Competition", value: this.competition},
			{label: "Event Name", value: this.eventName},
			{label: "Theme", value: this.theme},
			{label: "Language", value: this.language},
			{label: "Tiers", value: this.tiers.join(", ")},
			{label: "Logo Type", value: this.logoType},
		];
	}

	get disableGeolocation() {
		return this.getAttribute("data-disable-geolocation") === "true" ? true : false;
	}

	get region() {
		const region = Object.keys(REGIONS).find(
			(region) =>
				this.country && REGIONS[region as keyof typeof REGIONS].includes(this.country)
		);
		return region || "Unknown";
	}

	get breakpoint() {
		return getBreakpoint(window.innerWidth);
	}

	loadDefaultFont() {
		const font = document.createElement("link");
		font.href = "https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap";
		font.rel = "stylesheet";
		document.head.appendChild(font);
	}

	loadCustomFonts() {
		const fontFaceSheet = new CSSStyleSheet();
		fontFaceSheet.replaceSync(`
			@font-face {
				font-family: "FWWC20203_WEB";
				src: url("${FWWCFont}") format("woff");
			}
		`);
		document.adoptedStyleSheets = [fontFaceSheet];
	}

	async queryData() {
		try {
			const response = await axios.get<ISports>(process.env.JSON_URL + "poc.json");
			this.dataWithLogos = response.data;
		} catch (error) {
			console.log(error);
		}
	}

	async queryCountry() {
		try {
			const response = await axios.get<ICountry[]>(
				"https://api.fifa.com/api/geo/esigeo.json"
			);
			this.country = chain(response.data).first().get("CountryCode", "GB").value();
		} catch (error) {
			console.log(error);
			this.country = "GB";
		}
	}

	async connectedCallback() {
		window.adobeDataLayer = window.adobeDataLayer || [];
		await Promise.all([this.queryData(), this.queryCountry()]);
		this.render();
		window.addEventListener("resize", this.render);
	}

	attributeChangedCallback() {
		if (this.dataWithLogos) {
			this.render();
		}
	}

	disconnectedCallback() {
		window.removeEventListener("resize", this.render);
	}

	checkColour(property_Colour: string[] | null) {
		return Boolean(property_Colour && property_Colour.includes(this.theme));
	}

	checkRegions(property_Region: string[] | null) {
		if (!property_Region || this.disableGeolocation) {
			return true;
		}

		let allowedRegions: string[] = [];

		forEach(property_Region, (regionName) => {
			const foundCountryCodes: string[] = get(REGIONS, regionName, []);
			allowedRegions = concat(allowedRegions, foundCountryCodes);
		});

		return Boolean(this.country && allowedRegions.includes(this.country));
	}

	checkCompetitions(property_Competition: string[] | null) {
		if (!this.competition) {
			return true;
		}
		return Boolean(property_Competition && property_Competition.includes(this.competition));
	}

	checkEventNames(property_EventName: number[] | null) {
		if (!this.eventName) {
			return true;
		}
		return Boolean(property_EventName && property_EventName.includes(+this.eventName));
	}

	checkLogoType(property_Graphics_Logos_Type: string[] | null) {
		if (!this.logoType) {
			return true;
		}
		return Boolean(
			property_Graphics_Logos_Type && property_Graphics_Logos_Type.includes(this.logoType)
		);
	}

	checkSpecialPartner(logos: ISport[], tier: LogoStripTier) {
		const specialPartnerIndex = logos.findIndex(
			(item) => item.property_Logostrip_Special_Partner?.length
		);
		if (specialPartnerIndex === -1) {
			return;
		}

		const numColumns = get(TIER_COLUMN_MAP[tier], this.breakpoint);
		const numLogos = logos.length > numColumns ? numColumns : logos.length;
		if (numLogos % 2 === 0) {
			arrayMove(logos, specialPartnerIndex, 0);
		} else {
			const centerIndex = Math.floor(numLogos / 2);
			arrayMove(logos, specialPartnerIndex, centerIndex);
		}
	}

	isDivider(property_Graphics_Logos_Type: string[] | null) {
		return Boolean(
			property_Graphics_Logos_Type &&
				property_Graphics_Logos_Type.includes("LogoStripDivider")
		);
	}

	getTierTitle(tier: LogoStripTier) {
		const tierMap = get(TIER_TITLE_MAP, tier);
		const defaultTitle = get(TIER_TITLES[tier], this.language);
		if (!tierMap) {
			return defaultTitle;
		}
		if (this.competition === "FWWC" || this.eventName === "285026") {
			return get(tierMap["FWWC"], this.language, defaultTitle);
		}
		if (this.competition === "FCWC" || this.eventName === "289175") {
			return get(tierMap["FCWC"], this.language, defaultTitle);
		}
		return defaultTitle;
	}

	getLogoClass(sponsor: ISport) {
		if (sponsor.property_Logostrip_Special_Partner) {
			return sponsor.property_Logostrip_Special_Partner.join(" ");
		}
		return "";
	}

	track(sponsor: ISport) {
		if (!window.adobeDataLayer) {
			console.warn("No window.adobeDataLayer found!");
			return;
		}

		window.adobeDataLayer.push({
			event: "asset_click",
			asset_type: "LogoStrip",
			navigateToPage: sponsor.image,
			navigateToUrl: sponsor.property_Logostrip_URL,
		});
	}

	getLogoTemplate(className: string, sponsor: ISport) {
		const imageTag = document.createElement("img");
		imageTag.src = sponsor.thumbnails.webimage;
		imageTag.alt = sponsor.image;
		imageTag.classList.add("sponsor-image", className);

		if (sponsor.property_Logostrip_URL) {
			const anchorTag = document.createElement("a");
			anchorTag.onclick = () => this.track(sponsor);
			anchorTag.href = sponsor.property_Logostrip_URL;
			anchorTag.target = "_blank";
			anchorTag.appendChild(imageTag);
			return anchorTag;
		}

		return imageTag;
	}

	getDividerTemplate(className: string, divider: ISport) {
		const dividerTag = document.createElement("img");
		dividerTag.src = divider.thumbnails.webimage;
		dividerTag.alt = divider.image;
		dividerTag.classList.add("divider-image", className);
		return dividerTag;
	}

	getDebugData() {
		const debugContainer = document.createElement("div");
		debugContainer.classList.add("debug-container");
		this.debugData.forEach((data) => {
			const debugText = document.createElement("p");
			debugText.classList.add("debug-text");
			debugText.innerHTML = `<strong>${data.label}: </strong> ${data.value}`;
			debugContainer.appendChild(debugText);
		});
		return debugContainer;
	}

	getListImages(sponsors: ISport[], tier: LogoStripTier) {
		const filteredSponsors = sponsors
			.filter(
				(item) =>
					this.checkLogoType(item.property_Graphics_Logos_Type) &&
					this.checkColour(item.property_Colour) &&
					this.checkRegions(item.property_Region) &&
					this.checkCompetitions(item.property_Competition) &&
					this.checkEventNames(item.property_EventName)
			)
			.sort((a, b) => {
				const textA = a.image.toUpperCase();
				const textB = b.image.toUpperCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			});

		const logos = filteredSponsors.filter(
			(item) => !this.isDivider(item.property_Graphics_Logos_Type)
		);
		const divider = filteredSponsors.find((item) =>
			this.isDivider(item.property_Graphics_Logos_Type)
		);

		// Reorder logo based on special partner property
		this.checkSpecialPartner(logos, tier);

		const titleText = this.getTierTitle(tier);

		const classes = compact(["title", this.theme, this.competition, `e${this.eventName}`]);

		if (!logos.length) {
			return null;
		}

		const sponsorsWrapper = document.createElement("div");
		sponsorsWrapper.classList.add("sponsors-wrapper");

		const title = document.createElement("h3");
		title.classList.add(...classes);
		title.innerText = titleText;

		const imagesWrapper = document.createElement("div");
		imagesWrapper.classList.add("images-wrapper");

		logos.forEach((sponsor: ISport) => {
			const logoWrapper = document.createElement("div");
			logoWrapper.classList.add("sponsor-image-wrapper", tier);
			logoWrapper.appendChild(this.getLogoTemplate(tier, sponsor));
			if (divider) {
				logoWrapper.appendChild(this.getDividerTemplate(tier, divider));
			}
			imagesWrapper.appendChild(logoWrapper);
		});

		sponsorsWrapper.appendChild(title);
		sponsorsWrapper.appendChild(imagesWrapper);

		return sponsorsWrapper;
	}

	render() {
		if (!this.dataWithLogos) {
			throw new Error("No logo data available.");
		}

		if (!this.shadowRoot) {
			throw new Error("No shadow root available.");
		}

		const wrapper = this.shadowRoot.querySelector(".wrapper");

		if (!wrapper) {
			throw new Error("No wrapper element found.");
		}

		const children: HTMLElement[] = [];

		if (this.debug) {
			children.push(this.getDebugData());
		}

		this.tiers.forEach((tier) => {
			const data = get(this.dataWithLogos, tier, []);
			const logoTier = this.getListImages(data, tier);
			if (logoTier) {
				children.push(logoTier);
			}
		});

		wrapper.replaceChildren(...children);
	}
}

// Define the new element
customElements.define("logo-strip", LogoStrip);
